import React from 'react'

const Footer = () => {
  return (
    <div className="container-fluid footer">
    <div className="container">
    <div className="row">
    <div className="col-sm-8">
	<p className="f_left">© 2024 mkghosh.com. All Rights Reserved.</p>
	</div>
    <div className="col-sm-4">
	<p className="f_right">Built &amp; Managed by <a rel="noopener noreferrer" href="https://mkghosh.com" target="_blank">MK Ghosh</a></p>
	</div>
	</div>	
	</div>  
    </div>
  )
}

export default Footer
