import React from 'react'
import {Link,useNavigate} from "react-router-dom";

var isLogged=false;
if (localStorage.getItem('token') != null)
{
	var tmp=localStorage.getItem('user');
	var user2 = JSON.parse(tmp);
	isLogged=true;
}
const Header = () => {
    const navigate = useNavigate();
	const Logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/");
    }

  return (
    <div className="top_area">
	<div className="branding">
	<a href="/"><img alt="" width="100" src={process.env.PUBLIC_URL + "/logo.webp"} /></a>
	</div>
	<div className="top_menu">
	{isLogged ?(<h3 style={{textAlign: "center"}}>Welcome [{user2.name}]</h3>):(
       <h3 style={{textAlign: "center"}}>Welcome [UserName]</h3> 
    )}	
	<ul>
	<li><Link to="/dashboard">Dashboard</Link></li>
	{isLogged && user2.admin ?
	(<li><Link to="/users">Users</Link></li>):('')}
	
	<li><Link to="/projects">Projects</Link></li>
	<li><Link to="/#" onClick={() => Logout()}>Logout</Link></li>
	</ul>
	</div>
	</div>  
  )
}

export default Header
